import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Link, useNavigate } from "react-router-dom";
import { API_URL } from '../components/Constant';
import axios from 'axios';
import toast from 'react-hot-toast';



export default function Signin() {

    useEffect(() => {
        document.title = "Signin - Grandsave";
    },[])

    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [alert, setAlert] = useState('');

    const validateForm = () => {
        let formErrors = {};
        if (!email) formErrors.email = "Email is required";
        if (!password) formErrors.password = "Password is required";
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateForm()) {
            setAlert("Please fill in all fields");
            return;
        }
        try {
            const data = new FormData();
            data.append('password', password);
            data.append('email', email);
            const response = await axios.post(`${API_URL}/auth/signin`, data);
            setLoading(false)
            toast.success("Account login successfuly!")
            localStorage.setItem('GrandsaveAhadUser', JSON.stringify(response.data[0]));
            navigate("/dashboard");
        } catch (error) {
            toast.error("Invalid details!")
            setLoading(false)
        }
    };

    return (
        <>
            <Navbar></Navbar>
            <section style={{ position: 'relative', top: '120px' }} className="contact_form ">
                <div className="row justify-content-center container">
                    <div className="col-xl-2"></div>
                    <div className="col-xl-7 col-md-10 col-lg-7 wow fadeInRight">
                        <div className="contact_form">
                            <h2 className="text-center">Signin to your account!</h2>
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-xl-12">
                                        <input
                                            className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                            type="email"
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                    </div>
                                    <div className="col-md-12">
                                        <input
                                            className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                            type="password"
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                                    </div>
                                    <div className="col-12">
                                        <button className="common_btn" disabled={loading} type="submit">
                                            {loading ? 'Loading...' : 'Submit'}
                                        </button>
                                    </div>
                                </div>
                            </form><br></br>
                            <p className="dont_account">
                                Don't have an account? <Link to="/signup">Click here</Link>
                                <br></br>
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-2"></div>
                </div>
            </section>
            <br></br>
            <Footer></Footer>
        </>
    )
}
