import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Navbar() {
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const user_data = JSON.parse(localStorage.getItem('GrandsaveAhadUser'))
    const toggleNavbar = () => {
        setIsNavExpanded(!isNavExpanded);
    };

    return (
        <>
            <nav className="navbar navbar-expand-lg main_menu">
                <div className="container container_large">
                    <Link className="navbar-brand" to="/">
                        {/* <img src="/images/logo/logo.png" alt="RESTINA" class="img-fluid w-100" /> */}
                        <h1 style={{fontSize:'25px', marginTop:'14px'}} >Grandsave.</h1>
                    </Link>
                    <button
                        className={`navbar-toggler ${isNavExpanded ? "show" : ""}`}
                        type="button"
                        aria-controls="navbarSupportedContent"
                        aria-expanded={isNavExpanded ? "true" : "false"}
                        aria-label="Toggle navigation"
                        onClick={toggleNavbar}
                    >

                        {isNavExpanded ? <i className="pi pi-times"></i> : <i className="pi pi-align-justify"></i>}
                    </button>
                    <div className={`collapse navbar-collapse ${isNavExpanded ? "show" : ""}`} id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto">
                            <li className="nav-item">
                                <Link className="nav-link active" to="/">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link " to="/join-us">Join us</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link " to="/search-restaurants">Search Restaurants</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link " to="/about-us">About us</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link " to="/contact-us">Contact us</Link>
                            </li>
                            {user_data ?
                                <>
                                    <li className="nav-item">
                                        <Link className="nav-link " to="/logout">Logout</Link>
                                    </li>
                                </>
                                :
                                <>
                                    <li className="nav-item">
                                        <Link className="nav-link " to="/signin">Signin</Link>
                                    </li>
                                </>

                            }
                        </ul>
                        <ul className="menu_right">
                            {user_data ?
                                <>
                                    <li className="">
                                        <Link className="menu_order  common_btn" to="/dashboard">Dashboard</Link>
                                    </li>
                                </>
                                :
                                <>
                                    <li>
                                        <Link className="menu_order common_btn" to="/buy-membership">
                                            Buy membership
                                        </Link>
                                    </li>
                                </>

                            }

                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}
