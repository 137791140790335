export const API_URL = 'https://grandsave-backend.vercel.app/api/v1';
export const IMG_URL = 'https://grandsave-backend.vercel.app/restaurantsImg';


export function isoToHumanReadable(isoDate) {
    const date = new Date(isoDate);
    const humanReadable = date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    });
    return humanReadable;
  }

export function hasTimePassed(targetDateTime) {
    const [datePart, timePart] = targetDateTime.split(' ');
    const [year, month, day] = datePart.split('-');
    const [hours, minutes, seconds] = timePart.split(':');
    const targetDate = new Date(year, month - 1, day, hours, minutes, seconds);
    const now = new Date();
    console.log("hit");
    return targetDate < now;
  }
  

export const countryNames = [
    'African',
    'American',
    'Asian',
    'Bangladeshi',
    'Belgian',
    'Brazilian',
    'British',
    'Bulgarian',
    'Caribbean',
    'Chinese',
    'Cuban',
    'English',
    'Ethiopian',
    'European',
    'French',
    'Fusion',
    'German',
    'Goan',
    'Greek',
    'Indian',
    'Indonesian',
    'International',
    'Irish',
    'Italian',
    'Japanese',
    'Latin',
    'Lebanese',
    'Malaysian',
    'Mediterranean',
    'Mexican',
    'Middle Eastern',
    'Moroccan',
    'Nepalese',
    'Pakistani',
    'Persian',
    'Polish',
    'Portuguese',
    'Spanish',
    'Sri Lankan',
    'Sushi',
    'Tapas',
    'Thai',
    'Turkish',
    'Vegan',
    'Vietnamese'
];

export const offerData = [
    '50% Off food',
    '2 for 1',
    '25% Off Total bill',
    '25% Off Lunch',
    '50% Off Takeaway Coffee',
    '25% Off Coffee and Confectionery',
    '25% Off Take away Food',
    '25% Off Catering'
];


export const timeIntervals = [
    "06 - 07",
    "07 - 08",
    "08 - 09",
    "09 - 10",
    "10 - 11",
    "11 - 12",
    "12 - 13",
    "13 - 14",
    "14 - 15",
    "15 - 16",
    "16 - 17",
    "17 - 18",
    "18 - 19",
    "19 - 20",
    "20 - 21",
    "21 - 22",
    "22 - 23",
    "23 - 24",
    "24 - 01"
];