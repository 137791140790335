import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import axios from 'axios';
import { API_URL } from '../components/Constant';
import toast from 'react-hot-toast';


export default function Ihaveres() {
    useEffect(() => {
        document.title = "Join us - Grandsave";
    },[])
    
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
    });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email address is invalid';
        }
        if (!formData.phone) newErrors.phone = 'Phone number is required';
        if (!formData.subject) newErrors.subject = 'Subject is required';
        if (!formData.message) newErrors.message = 'Message is required';
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        const formErrors = validate();
        setErrors(formErrors);
        if (Object.keys(formErrors).length === 0) {
            try {
                const data = new FormData();
                data.append('name', formData.name);
                data.append('email', formData.email);
                data.append('number', formData.phone);
                data.append('subject', formData.subject);
                data.append('message', formData.message);
                data.append('type', "approval");
                const response = await axios.post(`${API_URL}/restaurants/add`, data);
                setLoading(false)
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    subject: '',
                    message: '',
                })
                toast.success(response.data.message)
            } catch (error) {
                toast.error("Oops try again later!")
                setLoading(false)
            }
        }
        setLoading(false)
    };

    return (
        <>
            <Navbar />
            <section className="contact_us pt_120 xs_pt_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2 col-md-8 wow fadeInLeft">
                        </div>
                        <div className="col-lg-8 wow fadeInRight">
                            <div className="contact_form">
                                <h2 className="text-center">Join us to list your Restaurant</h2>
                                <p className="text-center">Kindly provide such details and our admin contact you shortly!</p>
                                <form onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <input
                                                className={`form-control ${errors.name ? 'border-danger' : ''}`}
                                                type="text"
                                                placeholder="Restaurant Name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                            />
                                            {errors.name && <small className="text-danger">{errors.name}</small>}
                                        </div>
                                        <div className="col-md-6">
                                            <input
                                                className={`form-control ${errors.email ? 'border-danger' : ''}`}
                                                type="email"
                                                placeholder="Email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                            />
                                            {errors.email && <small className="text-danger">{errors.email}</small>}
                                        </div>
                                        <div className="col-md-6">
                                            <input
                                                className={`form-control ${errors.phone ? 'border-danger' : ''}`}
                                                type="text"
                                                placeholder="Phone Number"
                                                name="phone"
                                                value={formData.phone}
                                                onChange={handleChange}
                                            />
                                            {errors.phone && <small className="text-danger">{errors.phone}</small>}
                                        </div>
                                        <div className="col-md-6">
                                            <input
                                                className={`form-control ${errors.subject ? 'border-danger' : ''}`}
                                                type="text"
                                                placeholder="Subject"
                                                name="subject"
                                                value={formData.subject}
                                                onChange={handleChange}
                                            />
                                            {errors.subject && <small className="text-danger">{errors.subject}</small>}
                                        </div>
                                        <div className="col-md-12">
                                            <textarea
                                                rows={7}
                                                placeholder="Write Message..."
                                                name="message"
                                                value={formData.message}
                                                onChange={handleChange}
                                                className={`form-control ${errors.message ? 'border-danger' : ''}`}
                                            />
                                            {errors.message && <small className="text-danger">{errors.message}</small>}
                                            <br></br>
                                            <button  disabled={loading} className="common_btn" type="submit">{ loading ? 'loading...' : 'Submit now' }</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-8 wow fadeInLeft">
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}
