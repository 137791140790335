import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { API_URL, IMG_URL, countryNames, offerData, timeIntervals } from '../components/Constant'
import axios from 'axios'
import { SkeletonText } from '../components/Skeleton'
import { Link } from 'react-router-dom'



export default function Contact() {
    const [cuisine, setCuisine] = useState(false)
    const [cuisinedata, setCuisinedata] = useState([])

    const [offer, setoffer] = useState(false)
    const [offerdata, setofferdata] = useState([])
    const [search, setSearch] = useState('');

    const [availability, setavailability] = useState(false)
    const [availabilitydata, setavailabilitydata] = useState([])

    const [groupsize, setgroupsize] = useState(false)
    const [groupsizedata, setgroupsizedata] = useState('')

    const [monday, setMonday] = useState(false)
    const [tuesday, setTuesday] = useState(false)
    const [wednesday, setWednesday] = useState(false)
    const [thursday, setThursday] = useState(false)
    const [friday, setFriday] = useState(false)
    const [saturday, setSaturday] = useState(false)
    const [sunday, setSunday] = useState(false)

    useEffect(() => {
        document.title = "Deals - Grandsave";
    })

    const Cuisine = () => {
        setCuisine(!cuisine)
    }
    const Groupsizes = () => {
        setgroupsize(!groupsize)
    }
    const Offer = () => {
        setoffer(!offer)
    }
    const Availability = () => {
        setavailability(!availability)
    }
    const Monday = () => {
        setMonday(!monday)
    }
    const Tuesday = () => {
        setTuesday(!tuesday)
    }
    const Wednesday = () => {
        setWednesday(!wednesday)
    }
    const Thursday = () => {
        setThursday(!thursday)
    }
    const Friday = () => {
        setFriday(!friday)
    }
    const Saturday = () => {
        setSaturday(!saturday)
    }
    const Sunday = () => {
        setSunday(!sunday)
    }


    const Country = (item) => {
        setCuisinedata(data => {
            const index = data.indexOf(item);
            if (index === -1) {
                return [...data, item];
            } else {
                return data.filter((_, i) => i !== index);
            }
        });
    };

    const groups = (item) => {
        setgroupsizedata(item);
    };




    const Offerss = (item) => {
        setofferdata(data => {
            const index = data.indexOf(item);
            if (index === -1) {
                return [...data, item];
            } else {
                return data.filter((_, i) => i !== index);
            }
        });
    };

    const checkDayExists = (day, data) => {
        return data.some(item => item.days === day);
    };

    const aval = (day, timeInterval) => {
        console.log(day, timeInterval);
        setavailabilitydata(data => {
            const dayExists = checkDayExists(day, data);
            if (dayExists) {
                return data.map(item => {
                    if (item.days === day) {
                        const hours = item.hours;
                        const hourExists = hours.some(hour => hour.value === timeInterval);

                        const newHours = hourExists
                            ? hours.filter(hour => hour.value !== timeInterval) // Remove if exists
                            : [...hours, { value: timeInterval }];             // Add if not exists

                        return newHours.length > 0
                            ? { ...item, hours: newHours }
                            : null; // Remove the item from the array
                    }
                    return item;
                }).filter(Boolean); // Filter out null items
            } else {
                return [
                    ...data,
                    { days: day, hours: [{ value: timeInterval }] }
                ];
            }
        });
    };


    const removegroups = () => {
        setgroupsizedata('')
    }

    const remavadays = (indexToRemove) => {
        setavailabilitydata(data => {
            const newData = data.filter((_, index) => index !== indexToRemove);
            return newData;
        });
    };

    const remove = () => {
        setavailabilitydata([]);
        setgroupsizedata('')
        setCuisinedata([])
        setofferdata([])
    };

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    console.log(data);
    const datafetch = async () => {
        setLoading(true)
        const data = new FormData();
        data.append('cuisine', JSON.stringify(cuisinedata));
        data.append('businessOffer', JSON.stringify(offerdata));
        data.append('availability', JSON.stringify(availabilitydata));
        data.append('groupSize', groupsizedata);
        data.append('search', search);
        const response = await axios.post(`${API_URL}/restaurants/fetch/`, data);
        setData(response.data)
        setLoading(false)
    }

    useEffect(() => {
        datafetch();
    }, [])


    const handleSearch = () => {
        datafetch();
    };


    console.log(availabilitydata);
    return (
        <>
            <Navbar></Navbar>
            <section className="menu_grid_view">
                <div className="pd-30">
                    <br></br><br></br><br></br><br></br><br></br>

                    <h1 className='text-center' >Our deals and Resturents</h1>
                    <br></br>
                    <div className='col col-lg-12 row' >
                        <div className='col-lg-2' ></div>
                        <div className='col-lg-7' ><input placeholder='E.g Restaurant name' value={search} type='text' onChange={(e) => { setSearch(e.target.value) }} className='form-control' /></div>
                        <div className='col-lg-1' ><center><button onClick={handleSearch} style={{ marginTop: '4px' }} className="common_btn ml-4">Search</button></center></div>
                        <div className='col-lg-2' ></div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 col-md-6 order-2 wow fadeInLeft">
                            <div className="menu_sidebar sticky_sidebar">
                                <button onClick={datafetch} className='common_btn'>Apply Filters</button>
                                <button onClick={remove} style={{ marginLeft: '2px' }} className='common_btn'>Remove All</button>
                                <div className="sidebar_wizard sidebar_category mt_25">
                                    <h2 style={{ fontSize: '20px' }} >    <i className="pi pi-filter" />  Filters</h2>

                                    {cuisinedata.length > 0 &&
                                        <>
                                            <h3 className='fs-20'>Selected Cuisine</h3>
                                            {cuisinedata.map((item, index) => (
                                                <div onClick={() => Country(item)} className='cursor-pionter' style={{ padding: '3px' }} key={index}>
                                                    <p style={{ backgroundColor: 'rgb(242, 162, 42)', borderRadius: '4px', color: 'white' }} >{item} <i className="pi pi-times" style={{ float: 'right', marginTop: '3px' }}></i></p>
                                                </div>
                                            ))}
                                        </>
                                    }

                                    {groupsizedata &&
                                        <>
                                            <h3 className='fs-20'>Selected Groups Size</h3>
                                            <div onClick={() => removegroups()} className='cursor-pionter' style={{ padding: '3px' }}>
                                                <p style={{ backgroundColor: 'rgb(242, 162, 42)', borderRadius: '4px', color: 'white' }} >{groupsizedata} <i className="pi pi-times" style={{ float: 'right', marginTop: '3px' }}></i></p>
                                            </div>
                                        </>
                                    }

                                    {offerdata.length > 0 &&
                                        <>
                                            <h3 className='fs-20'>Selected Offers</h3>
                                            {offerdata.map((item, index) => (
                                                <div onClick={() => Offerss(item)} className='cursor-pionter' style={{ padding: '3px' }} key={index}>
                                                    <p style={{ backgroundColor: 'rgb(242, 162, 42)', borderRadius: '4px', color: 'white' }} >{item} <i className="pi pi-times" style={{ float: 'right', marginTop: '3px' }}></i></p>
                                                </div>
                                            ))}
                                        </>
                                    }

                                    {availabilitydata.length > 0 &&
                                        <>
                                            <h3 className='fs-20'>Selected Availability</h3>
                                            {availabilitydata.map((item, index) => (
                                                item.hours.length > 0 &&
                                                <div className='cursor-pointer' style={{ padding: '3px' }} key={index}>
                                                    <p onClick={() => remavadays(index)} style={{ backgroundColor: 'rgb(242, 162, 42)', borderRadius: '4px', color: 'white' }}>
                                                        {item.days}
                                                        <i className="pi pi-times" style={{ float: 'right', marginTop: '3px' }}></i>
                                                    </p>
                                                    {item.hours.map((items, index) => (
                                                        <p onClick={() => aval(item.days, items.value)} style={{ backgroundColor: 'rgb(242, 162, 42)', borderRadius: '4px', color: 'white', marginLeft: '25px', marginTop: '4px' }} key={index}>
                                                            {items.value}
                                                            <i className="pi pi-times" style={{ float: 'right', marginTop: '3px' }}></i>
                                                        </p>
                                                    ))}
                                                </div>
                                            ))}
                                        </>
                                    }

                                    <br></br>
                                    <ul>
                                        <li>
                                            <div className='sidemenue cursor-pionter' onClick={Cuisine} >
                                                <h3 className='fs-20'>Cuisine</h3>
                                                <div>{cuisine ? <> <img style={{ width: '30px', float: 'right' }} src="/images/svg/2.svg" /></> : <><img style={{ width: '30px', float: 'right' }} src="/images/svg/1.svg" /></>}</div>
                                            </div>
                                            {cuisine &&
                                                <>
                                                    <div className='ml-8  cursor-pionter ' >
                                                        {countryNames.map((item, index) => (
                                                            <div style={{ padding: '3px' }} key={index}>
                                                                <p onClick={() => Country(item)} style={cuisinedata.includes(item) ? { backgroundColor: 'rgb(242, 162, 42)', borderRadius: '4px', color: 'white' } : {}}>{item}
                                                                    {cuisinedata.includes(item) &&
                                                                        <i className="pi pi-times" style={{ float: 'right', marginTop: '3px' }}></i>
                                                                    }
                                                                </p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            }
                                        </li>
                                        <li>
                                            <div className='sidemenue cursor-pionter' onClick={Groupsizes} >
                                                <h3 className='fs-20'>Group Size</h3>
                                                <div>{groupsize ? <> <img style={{ width: '30px', float: 'right' }} src="/images/svg/2.svg" /></> : <><img style={{ width: '30px', float: 'right' }} src="/images/svg/1.svg" /></>}</div>
                                            </div>
                                            {groupsize &&
                                                <>
                                                    <div className='ml-8  cursor-pionter ' >
                                                        {[...Array(15).keys()].map((num, index) => (
                                                            <div style={{ padding: '3px' }} key={index}>
                                                                <p onClick={() => groups(num + 1)} style={groupsizedata == num + 1 ? { backgroundColor: 'rgb(242, 162, 42)', borderRadius: '4px', color: 'white' } : {}} >{num + 1}
                                                                    {groupsizedata == num + 1 &&
                                                                        <i className="pi pi-times" style={{ float: 'right', marginTop: '3px' }}></i>
                                                                    }
                                                                </p>
                                                            </div>
                                                        ))}
                                                        <div style={{ padding: '3px' }}>
                                                            <p onClick={() => groups("No Limit")} style={groupsizedata == "No Limit" ? { backgroundColor: 'rgb(242, 162, 42)', borderRadius: '4px', color: 'white' } : {}} >No Limit
                                                                {groupsizedata == "No Limit" &&
                                                                    <i className="pi pi-times" style={{ float: 'right', marginTop: '3px' }}></i>
                                                                }
                                                            </p>
                                                        </div>

                                                    </div>
                                                </>
                                            }
                                        </li>
                                        <li>
                                            <div className='sidemenue cursor-pionter' onClick={Offer} >
                                                <h3 className='fs-20'>Restaurang Offer</h3>
                                                <div>{offer ? <> <img style={{ width: '30px', float: 'right' }} src="/images/svg/2.svg" /></> : <><img style={{ width: '30px', float: 'right' }} src="/images/svg/1.svg" /></>}</div>
                                            </div>
                                            {offer &&
                                                <>
                                                    <div className='ml-8  cursor-pionter ' >
                                                        {offerData.map((item, index) => (
                                                            <div style={{ padding: '3px' }} key={index}>
                                                                <p onClick={() => Offerss(item)} style={offerdata.includes(item) ? { backgroundColor: 'rgb(242, 162, 42)', borderRadius: '4px', color: 'white' } : {}}>{item}
                                                                    {offerdata.includes(item) &&
                                                                        <i className="pi pi-times" style={{ float: 'right', marginTop: '3px' }}></i>
                                                                    }
                                                                </p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            }

                                        </li>
                                        <li>
                                            <div className='sidemenue cursor-pionter' onClick={Availability} >
                                                <h3 className='fs-20' >Availability</h3>
                                                <div>{availability ? <> <img style={{ width: '30px', float: 'right' }} src="/images/svg/2.svg" /></> : <><img style={{ width: '30px', float: 'right' }} src="/images/svg/1.svg" /></>}</div>
                                            </div>
                                            {availability &&
                                                <>
                                                    <div className='ml-8 cursor-pionter' >
                                                        <div className='sidemenue cursor-pionter' onClick={Monday} >
                                                            <h3 className='fs-16' >Monday</h3>
                                                            <div>{monday ? <> <img style={{ width: '25px', float: 'right' }} src="/images/svg/2.svg" /></> : <><img style={{ width: '25px', float: 'right' }} src="/images/svg/1.svg" /></>}</div>
                                                        </div>
                                                        {monday &&
                                                            <>
                                                                {timeIntervals.map((item, index) => (
                                                                    <div style={{ padding: '3px' }} key={index}>
                                                                        <p
                                                                            onClick={() => aval("Monday", item)}
                                                                            style={
                                                                                availabilitydata.some(data => data.days == "Monday" && data.hours.some(hour => hour.value === item))
                                                                                    ? { backgroundColor: 'rgb(242, 162, 42)', borderRadius: '4px', color: 'white' }
                                                                                    : {}
                                                                            }
                                                                        >
                                                                            {item}
                                                                            {availabilitydata.some(data => data.days == "Monday" && data.hours.some(hour => hour.value === item)) &&
                                                                                <i className="pi pi-times" style={{ float: 'right', marginTop: '3px' }}></i>
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        }

                                                        <div className='sidemenue cursor-pionter' onClick={Tuesday} >
                                                            <h3 className='fs-16' >Tuesday</h3>
                                                            <div>{tuesday ? <> <img style={{ width: '25px', float: 'right' }} src="/images/svg/2.svg" /></> : <><img style={{ width: '25px', float: 'right' }} src="/images/svg/1.svg" /></>}</div>
                                                        </div>
                                                        {tuesday &&
                                                            <>
                                                                {timeIntervals.map((item, index) => (
                                                                    <div style={{ padding: '3px' }} key={index}>
                                                                        <p
                                                                            onClick={() => aval("Tuesday", item)}
                                                                            style={
                                                                                availabilitydata.some(data => data.days == "Tuesday" && data.hours.some(hour => hour.value === item))
                                                                                    ? { backgroundColor: 'rgb(242, 162, 42)', borderRadius: '4px', color: 'white' }
                                                                                    : {}
                                                                            }
                                                                        >
                                                                            {item}
                                                                            {availabilitydata.some(data => data.days == "Tuesday" && data.hours.some(hour => hour.value === item)) &&
                                                                                <i className="pi pi-times" style={{ float: 'right', marginTop: '3px' }}></i>
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        }


                                                        <div className='sidemenue cursor-pionter' onClick={Wednesday} >
                                                            <h3 className='fs-16' >Wednesday</h3>
                                                            <div>{wednesday ? <> <img style={{ width: '25px', float: 'right' }} src="/images/svg/2.svg" /></> : <><img style={{ width: '25px', float: 'right' }} src="/images/svg/1.svg" /></>}</div>
                                                        </div>
                                                        {wednesday &&
                                                            <>
                                                                {timeIntervals.map((item, index) => (
                                                                    <div style={{ padding: '3px' }} key={index}>
                                                                        <p
                                                                            onClick={() => aval("Wednesday", item)}
                                                                            style={
                                                                                availabilitydata.some(data => data.days == "Wednesday" && data.hours.some(hour => hour.value === item))
                                                                                    ? { backgroundColor: 'rgb(242, 162, 42)', borderRadius: '4px', color: 'white' }
                                                                                    : {}
                                                                            }
                                                                        >
                                                                            {item}
                                                                            {availabilitydata.some(data => data.days == "Wednesday" && data.hours.some(hour => hour.value === item)) &&
                                                                                <i className="pi pi-times" style={{ float: 'right', marginTop: '3px' }}></i>
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        }

                                                        <div className='sidemenue cursor-pionter' onClick={Thursday} >
                                                            <h3 className='fs-16' >Thursday</h3>
                                                            <div>{thursday ? <> <img style={{ width: '25px', float: 'right' }} src="/images/svg/2.svg" /></> : <><img style={{ width: '25px', float: 'right' }} src="/images/svg/1.svg" /></>}</div>
                                                        </div>
                                                        {thursday &&
                                                            <>
                                                                {timeIntervals.map((item, index) => (
                                                                    <div style={{ padding: '3px' }} key={index}>
                                                                        <p
                                                                            onClick={() => aval("Thursday", item)}
                                                                            style={
                                                                                availabilitydata.some(data => data.days == "Thursday" && data.hours.some(hour => hour.value === item))
                                                                                    ? { backgroundColor: 'rgb(242, 162, 42)', borderRadius: '4px', color: 'white' }
                                                                                    : {}
                                                                            }
                                                                        >
                                                                            {item}
                                                                            {availabilitydata.some(data => data.days == "Thursday" && data.hours.some(hour => hour.value === item)) &&
                                                                                <i className="pi pi-times" style={{ float: 'right', marginTop: '3px' }}></i>
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        }

                                                        <div className='sidemenue cursor-pionter' onClick={Friday} >
                                                            <h3 className='fs-16' >Friday</h3>
                                                            <div>{friday ? <> <img style={{ width: '25px', float: 'right' }} src="/images/svg/2.svg" /></> : <><img style={{ width: '25px', float: 'right' }} src="/images/svg/1.svg" /></>}</div>
                                                        </div>
                                                        {friday &&
                                                            <>
                                                                {timeIntervals.map((item, index) => (
                                                                    <div style={{ padding: '3px' }} key={index}>
                                                                        <p
                                                                            onClick={() => aval("Friday", item)}
                                                                            style={
                                                                                availabilitydata.some(data => data.days == "Friday" && data.hours.some(hour => hour.value === item))
                                                                                    ? { backgroundColor: 'rgb(242, 162, 42)', borderRadius: '4px', color: 'white' }
                                                                                    : {}
                                                                            }
                                                                        >
                                                                            {item}
                                                                            {availabilitydata.some(data => data.days == "Friday" && data.hours.some(hour => hour.value === item)) &&
                                                                                <i className="pi pi-times" style={{ float: 'right', marginTop: '3px' }}></i>
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        }

                                                        <div className='sidemenue cursor-pionter' onClick={Saturday} >
                                                            <h3 className='fs-16' >Saturday</h3>
                                                            <div>{saturday ? <> <img style={{ width: '25px', float: 'right' }} src="/images/svg/2.svg" /></> : <><img style={{ width: '25px', float: 'right' }} src="/images/svg/1.svg" /></>}</div>
                                                        </div>
                                                        {saturday &&
                                                            <>
                                                                {timeIntervals.map((item, index) => (
                                                                    <div style={{ padding: '3px' }} key={index}>
                                                                        <p
                                                                            onClick={() => aval("Saturday", item)}
                                                                            style={
                                                                                availabilitydata.some(data => data.days == "Saturday" && data.hours.some(hour => hour.value === item))
                                                                                    ? { backgroundColor: 'rgb(242, 162, 42)', borderRadius: '4px', color: 'white' }
                                                                                    : {}
                                                                            }
                                                                        >
                                                                            {item}
                                                                            {availabilitydata.some(data => data.days == "Saturday" && data.hours.some(hour => hour.value === item)) &&
                                                                                <i className="pi pi-times" style={{ float: 'right', marginTop: '3px' }}></i>
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        }

                                                        <div className='sidemenue cursor-pionter' onClick={Sunday} >
                                                            <h3 className='fs-16' >Sunday</h3>
                                                            <div>{sunday ? <> <img style={{ width: '25px', float: 'right' }} src="/images/svg/2.svg" /></> : <><img style={{ width: '25px', float: 'right' }} src="/images/svg/1.svg" /></>}</div>
                                                        </div>
                                                        {sunday &&
                                                            <>
                                                                {timeIntervals.map((item, index) => (
                                                                    <div style={{ padding: '3px' }} key={index}>
                                                                        <p
                                                                            onClick={() => aval("Sunday", item)}
                                                                            style={
                                                                                availabilitydata.some(data => data.days == "Sunday" && data.hours.some(hour => hour.value === item))
                                                                                    ? { backgroundColor: 'rgb(242, 162, 42)', borderRadius: '4px', color: 'white' }
                                                                                    : {}
                                                                            }
                                                                        >
                                                                            {item}
                                                                            {availabilitydata.some(data => data.days == "Sunday" && data.hours.some(hour => hour.value === item)) &&
                                                                                <i className="pi pi-times" style={{ float: 'right', marginTop: '3px' }}></i>
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        }
                                                    </div>
                                                </>
                                            }

                                        </li>

                                    </ul>
                                </div>

                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-8 order-lg-2">
                            {loading ?
                                <>
                                    <div className="row">
                                        <div className="col-xl-4 col-lg-4 col-sm-6 wow fadeInUp">
                                            <div className="skeleton-wrapper">
                                                <SkeletonText width="100%" height="300px" />
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-sm-6 wow fadeInUp">
                                            <div className="skeleton-wrapper">
                                                <SkeletonText width="100%" height="300px" />
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-sm-6 wow fadeInUp">
                                            <div className="skeleton-wrapper">
                                                <SkeletonText width="100%" height="300px" />
                                            </div>
                                        </div>

                                    </div>

                                </>
                                :
                                <>
                                    <div className="row">
                                        {data.length > 0 ?
                                            <>
                                                {data.map((item, index) => (
                                                    <div className="col-xl-4 col-lg-4 col-sm-6 wow fadeInUp">
                                                        <div className="single_menu">
                                                            <div className="single_menu_img">
                                                                <Link className="title" to={`/restaurants/${item._id}`}>
                                                                    <img src={`${item.profilePicture}`} className="img-fluid w-100" />
                                                                </Link>
                                                            </div>
                                                            <div className="single_menu_text">
                                                                <Link className="category" to={`/restaurants/${item._id}`}>
                                                                    {item.businessOffer[0].label}
                                                                </Link>
                                                                <Link className="title" to={`/restaurants/${item._id}`}>
                                                                    {item.name} <span style={{ fontSize: '15px' }} >({item.city})</span>
                                                                </Link>
                                                                <Link className="title" to={`/restaurants/${item._id}`}>
                                                                    <p className="descrption">
                                                                        {item.description}
                                                                    </p>
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                            :
                                            <>
                                                <center>
                                                    <div style={{ marginTop: '10%' }} >
                                                        <img src="/question-mark.png" style={{ width: '100px' }} ></img>
                                                        No Restaurants exits in our records according to applied filters!
                                                    </div>
                                                </center>
                                            </>
                                        }
                                    </div>
                                </>

                            }

                        </div>
                    </div>
                </div >
            </section >
            <Footer></Footer>
        </>
    )
}
