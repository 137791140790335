import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from 'react-router-dom'
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { MyCheckoutForm } from "../components/MyCheckoutForm";
import "../components/payment.css";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import axios from "axios";
import { API_URL } from "../components/Constant";
import toast from "react-hot-toast";

const SignupSchema = Yup.object().shape({
    fullName: Yup.string().required("Full Name is required"),
    mobileNo: Yup.string().required("Mobile no is required"),
    streetAddress: Yup.string().required("Street Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State / Province is required"),
    zipCode: Yup.string().required("ZIP / Postal Code is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
    membershipCard: Yup.string().required("Please choose a membership card"),
});

const getBorderColor = (errors, touched, fieldName) => {
    return errors[fieldName] && touched[fieldName] ? 'border-danger' : touched[fieldName] ? '' : '';
};

const stripePromise = loadStripe("pk_test_51LmwrHHXMKmtdvtePr7PPMiN2XXMKGIlRamtCHGYMmQqeuqC1qMpdZzGw2XV79BsDeu6EC7cZPukYjCycbwNTKSs002sfa47Vl");

export default function Signup() {
    
    useEffect(() => {
        document.title = "Signup - Grandsave";
    },[])

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const onCloseModal = () => setOpen(false);
    const storedData = JSON.parse(localStorage.getItem('plandata'));

    useEffect(() => {
        if (!storedData) {
            navigate('/buy-membership');
        }
    }, [navigate]);

    const formik = useFormik({
        initialValues: {
            fullName: "",
            mobileNo: "",
            streetAddress: "",
            city: "",
            state: "",
            zipCode: "",
            email: "",
            password: "",
            membershipCard: "",
        },
        validationSchema: SignupSchema,
        onSubmit: async (values) => {
            try {
                setLoading(true);
                const data = new FormData();
                data.append('email', values.email);
                const response = await axios.post(`${API_URL}/auth/unique-email`, data);
                console.log("Unique email check response:", response);
                setOpen(true);
                setLoading(false);
            } catch (error) {
                console.error("Error during unique email check:", error);
                toast.error("Email is already used by existing users");
                setLoading(false);
            }
        }
    });

    return (
        <>
            <Navbar />
            <section style={{ position: 'relative', top: '120px' }} className="contact_form">
                <div className="row justify-content-center container">
                    <div className="col-xl-2"></div>
                    <div className="col-xl-8 col-md-10 col-lg-7 wow fadeInRight">
                        <div className="contact_form">
                            <h2 className="text-center">Register yourself to Subscribe {storedData?.title || "the plan"}</h2>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <input
                                            className={`form-control ${getBorderColor(formik.errors, formik.touched, 'fullName')}`}
                                            name="fullName"
                                            placeholder="Full Name"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.fullName}
                                        />
                                        <small>
                                            <div className="text-danger">
                                                {formik.touched.fullName && formik.errors.fullName ? formik.errors.fullName : null}
                                            </div>
                                        </small>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            className={`form-control ${getBorderColor(formik.errors, formik.touched, 'mobileNo')}`}
                                            name="mobileNo"
                                            placeholder="Mobile no"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.mobileNo}
                                        />
                                        <small>
                                            <div className="text-danger">
                                                {formik.touched.mobileNo && formik.errors.mobileNo ? formik.errors.mobileNo : null}
                                            </div>
                                        </small>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            className={`form-control ${getBorderColor(formik.errors, formik.touched, 'streetAddress')}`}
                                            name="streetAddress"
                                            placeholder="Street Address"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.streetAddress}
                                        />
                                        <small>
                                            <div className="text-danger">
                                                {formik.touched.streetAddress && formik.errors.streetAddress ? formik.errors.streetAddress : null}
                                            </div>
                                        </small>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            className={`form-control ${getBorderColor(formik.errors, formik.touched, 'city')}`}
                                            name="city"
                                            placeholder="City"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.city}
                                        />
                                        <small>
                                            <div className="text-danger">
                                                {formik.touched.city && formik.errors.city ? formik.errors.city : null}
                                            </div>
                                        </small>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            className={`form-control ${getBorderColor(formik.errors, formik.touched, 'state')}`}
                                            name="state"
                                            placeholder="State / Province"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.state}
                                        />
                                        <small>
                                            <div className="text-danger">
                                                {formik.touched.state && formik.errors.state ? formik.errors.state : null}
                                            </div>
                                        </small>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            className={`form-control ${getBorderColor(formik.errors, formik.touched, 'zipCode')}`}
                                            name="zipCode"
                                            placeholder="ZIP / Postal Code"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.zipCode}
                                        />
                                        <small>
                                            <div className="text-danger">
                                                {formik.touched.zipCode && formik.errors.zipCode ? formik.errors.zipCode : null}
                                            </div>
                                        </small>
                                    </div>
                                    <div className="col-xl-12">
                                        <input
                                            className={`form-control ${getBorderColor(formik.errors, formik.touched, 'email')}`}
                                            name="email"
                                            type="email"
                                            placeholder="Email"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.email}
                                        />
                                        <small>
                                            <div className="text-danger">
                                                {formik.touched.email && formik.errors.email ? formik.errors.email : null}
                                            </div>
                                        </small>
                                    </div>
                                    <div className="col-md-12">
                                        <input
                                            className={`form-control ${getBorderColor(formik.errors, formik.touched, 'password')}`}
                                            name="password"
                                            type="password"
                                            placeholder="Password"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.password}
                                        />
                                        <small>
                                            <div className="text-danger">
                                                {formik.touched.password && formik.errors.password ? formik.errors.password : null}
                                            </div>
                                        </small>
                                    </div>
                                    <div className="col-md-12 mt-4">
                                        <select
                                            className={`form-control ${getBorderColor(formik.errors, formik.touched, 'membershipCard')}`}
                                            name="membershipCard"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.membershipCard}
                                        >
                                            <option value="" style={{ display: 'none' }}>Choose A Membership Card</option>
                                            <option value="Physical">Physical</option>
                                            <option value="Digital">Digital</option>
                                        </select>
                                        <small>
                                            <div className="text-danger">
                                                {formik.touched.membershipCard && formik.errors.membershipCard ? formik.errors.membershipCard : null}
                                            </div>
                                        </small>
                                    </div>

                                    <div className="col-12">
                                        <button className="common_btn" disabled={loading} type="submit">
                                            {loading ? 'Loading...' : 'Submit'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                            <br />
                            <p className="dont_account">
                                Have an account? <Link to="/signin">Sign in</Link>
                                <br />
                            </p>
                        </div>
                    </div>
                    <div className="col-xl-2"></div>
                </div>
            </section>
            <br />
            <Modal styles={{ width: '800px' }} open={open} onClose={onCloseModal} center>
                <Elements stripe={stripePromise}>
                    <h2 style={{ fontSize: '20px' }} >Provide following details to subscribe the plan </h2>
                    <MyCheckoutForm datas={formik.values} id={storedData?.id} storedData={storedData} onCloseModal={onCloseModal} />
                </Elements>
            </Modal>
            <Footer />
        </>
    );
}
