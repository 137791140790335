import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { API_URL, IMG_URL } from '../components/Constant';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { SkeletonText } from '../components/Skeleton';


export default function RestaurantsDetail() {
    useEffect(() => {
        document.title = "Restaurants - Grandsave";
    },[])
    
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [activeTab, setActiveTab] = useState('menu-details');
    const [activeImage, setActiveImage] = useState();

    const datafetch = async () => {
        const formData = new FormData();
        formData.append('id', id);
        const response = await axios.post(`${API_URL}/restaurants`, formData);
        setData(response.data);
        setActiveImage(response.data[0].profilePicture);
        setLoading(false);
    }

    useEffect(() => {
        datafetch();
    }, [id]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    }

    const handleImageClick = (image) => {
        setActiveImage(image);
    }

    return (
        <>
            <Navbar />
            <br /><br /><br /><br />

            {loading ? (
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-md-8 col-lg-8 wow fadeInLeft">
                            <div className="container contact_form">
                                <div className="skeleton-wrapper">
                                    <SkeletonText width="100%" height="400px" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 col-md-8 col-lg-8 wow fadeInLeft">
                            <div className="container contact_form">
                                <div className="skeleton-wrapper">
                                    <SkeletonText width="100%" height="400px" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            ) : (
                <>
                    <section className="menu_details pt_120 xs_pt_100">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-4 col-md-8 col-lg-5 wow fadeInLeft">
                                    <div className="menu_det_slider_area sticky_sidebar">
                                        <div className="row slider-for">
                                            <div className="col-12">
                                                <div className="details_large_img">
                                                    <img src={`${activeImage}`} className="img-fluid w-100" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row slider-nav">
                                            {data[0].images.length > 0 ?
                                                <>
                                                    {data[0].images.map((item, index) => (
                                                        <>
                                                            <div className="col-xl-3">
                                                                <div className="details_small_img">
                                                                    <img onClick={() => handleImageClick(item)} src={`${item}`} className="img-fluid w-100" />
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))}
                                                </>
                                                :
                                                <></>
                                            }
                                            <div className="col-xl-3">

                                                <div className="details_small_img">
                                                    <img onClick={() => handleImageClick(data[0].profilePicture)} src={`${data[0].profilePicture}`} className="img-fluid w-100" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-8 col-md-8 col-lg-7 wow fadeInUp">
                                    <div className="menu_det_text">
                                        <h2 className="details_title">{data[0].name}<span>({data[0].city})</span></h2>
                                        <div className="details_short_description">
                                            <h3>Description</h3>
                                            <p>{data[0].description}</p>
                                        </div>
                                        <div className="details_size">
                                            <h5>General Information</h5>
                                            <div className="form-check">
                                                <label className="form-check-label" htmlFor="businessType">
                                                    <span>Business Type:</span> <span style={{ marginLeft: '20px' }}>{data[0].businesstype}</span>
                                                </label>
                                                <label className="form-check-label" htmlFor="streetAddress">
                                                    <span>Street Address:</span> <span style={{ marginLeft: '20px' }}>{data[0].streetAddress}</span>
                                                </label>
                                                <label className="form-check-label" htmlFor="postNumber">
                                                    <span>Post Number:</span> <span style={{ marginLeft: '20px' }}>{data[0].postNumber}</span>
                                                </label>
                                                <label className="form-check-label" htmlFor="city">
                                                    <span>City:</span> <span style={{ marginLeft: '20px' }}>{data[0].city}</span>
                                                </label>
                                                <label className="form-check-label" htmlFor="telephoneNumber">
                                                    <span>Number:</span> <span style={{ marginLeft: '20px' }}>{data[0].telephoneNumber}</span>
                                                </label>
                                                <label className="form-check-label" htmlFor="email">
                                                    <span>Email:</span> <span style={{ marginLeft: '20px' }}>{data[0].email}</span>
                                                </label>
                                                <label className="form-check-label" htmlFor="webpageLink">
                                                    <span>Social:</span> <span style={{ marginLeft: '20px' }}>{data[0].webpageLink}</span>
                                                </label>
                                                <label className="form-check-label" htmlFor="groupSize">
                                                    <span>Group Size:</span> <span style={{ marginLeft: '20px' }}>{data[0].groupSize}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row xs_mt_100 wow fadeInUp">
                                <div className="col-12">
                                    <div className="menu_det_content_area">
                                        <nav>
                                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                                <button
                                                    className={`nav-link ${activeTab === 'menu-details' ? 'active' : ''}`}
                                                    onClick={() => handleTabClick('menu-details')}
                                                    type="button"
                                                    role="tab"
                                                >
                                                    Menu details
                                                </button>
                                                <button
                                                    className={`nav-link ${activeTab === 'availability' ? 'active' : ''}`}
                                                    onClick={() => handleTabClick('availability')}
                                                    type="button"
                                                    role="tab"
                                                >
                                                    Availability
                                                </button>
                                                <button
                                                    className={`nav-link ${activeTab === 'cuisine' ? 'active' : ''}`}
                                                    onClick={() => handleTabClick('cuisine')}
                                                    type="button"
                                                    role="tab"
                                                >
                                                    Cuisine
                                                </button>
                                                <button
                                                    className={`nav-link ${activeTab === 'offers' ? 'active' : ''}`}
                                                    onClick={() => handleTabClick('offers')}
                                                    type="button"
                                                    role="tab"
                                                >
                                                    Restaurant Offer
                                                </button>
                                            </div>
                                        </nav>
                                        <div className="tab-content" id="nav-tabContent">
                                            <div
                                                className={`tab-pane fade ${activeTab === 'menu-details' ? 'show active' : ''}`}
                                                id="nav-menu-details"
                                                role="tabpanel"
                                            >
                                                <div className="menu_det_review_area">
                                                    <p>{data[0].menuDetails}</p>
                                                </div>
                                            </div>
                                            <div
                                                className={`tab-pane fade ${activeTab === 'availability' ? 'show active' : ''}`}
                                                id="nav-availability"
                                                role="tabpanel"
                                            >
                                                <div className="menu_det_review_area">
                                                    {data[0].availability.length > 0 ?
                                                        <div className="table-responsive">
                                                            <table className="table mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <td><span>Days</span></td>
                                                                        <td><span>Hours</span></td>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {data[0].availability.map((item, index) => (
                                                                        <tr key={index} >
                                                                            <td>{item.days}</td>
                                                                            <td>
                                                                                {item.hours.map((item, indexx) => (
                                                                                    <>
                                                                                        <span key={indexx} style={{ marginRight: '6px' }} >{item.label},</span>
                                                                                    </>
                                                                                ))
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        :
                                                        <center><div >No Availability exits</div></center>
                                                    }
                                                </div>
                                            </div>
                                            <div
                                                className={`tab-pane fade ${activeTab === 'cuisine' ? 'show active' : ''}`}
                                                id="nav-cuisine"
                                                role="tabpanel"
                                            >
                                                <div className="menu_det_review_area">
                                                    {data[0].Cuisine.length > 0 ?
                                                        <>
                                                            {data[0].Cuisine.map((item, index) => (
                                                                <p><b>{item.value}</b> </p>
                                                            ))}
                                                        </>
                                                        :
                                                        <center><div >No Cuisine exits</div></center>
                                                    }
                                                </div>
                                            </div>
                                            <div
                                                className={`tab-pane fade ${activeTab === 'offers' ? 'show active' : ''}`}
                                                id="nav-offers"
                                                role="tabpanel"
                                            >
                                                <div className="menu_det_review_area">
                                                    {data[0].businessOffer.length > 0 ?
                                                        <>
                                                            {data[0].businessOffer.map((item, index) => (
                                                                <p><b>{item.value}</b> </p>
                                                            ))}
                                                        </>
                                                        :
                                                        <center><div >No business Offer exits</div></center>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            )
            }
            <Footer />
        </>
    );
}
