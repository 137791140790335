// src/Skeleton.js
import React from 'react';
import './skeleton.css';

export const SkeletonText = ({width, height}) => (
  <div style={{width: width, height: height}}  className="skeleton skeleton-animated"></div>
);

export const SkeletonImage = () => (
  <div className="skeleton skeleton-image skeleton-animated"></div>
);

export const SkeletonAvatar = () => (
  <div className="skeleton skeleton-avatar skeleton-animated"></div>
);
