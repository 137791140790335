import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import toast from "react-hot-toast";
import { API_URL, addDaysToDate } from './Constant';
import { useNavigate } from 'react-router-dom';


export const MyCheckoutForm = ({datas, id, storedData, onCloseModal}) => {
    const navigate = useNavigate();
    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: "#424770",
                fontWeight: 500,
                fontSize: "18px",
                fontSmoothing: "antialiased",
                lineHeight: "3.5",
                padding: "15px 17px",
                backgroundColor: "#ffffff",
                border: "2px solid grey",
                borderRadius: "4px",
                boxShadow: "0 4px 6px rgba(50,50,93,.1), 0 1px 3px rgba(0,0,0,.08)",
                "::placeholder": {
                    color: "#aab7c4",
                    fontStyle: "bold",
                },
                ":-webkit-autofill": {
                    backgroundColor: "#fce883",
                    fontSize: "18px",
                },
            },
            invalid: {
                color: "#9e2146",
                iconColor: "#9e2146",
                fontWeight: "bold",
                border: "2px solid #ff6347",
            },
        },
        hidePostalCode: true,
    };

    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [paymentSucceeded, setPaymentSucceeded] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);
        setLoading(true);

        if (!stripe || !elements) {
            return;
        }

        const card = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: card,
        });

        if (error) {
            setLoading(false);
            setError(error.message);
        } else {
            const paymentMethodId = paymentMethod.id;
            try {
                const response = await axios.post(`${API_URL}/general/stripe`, {
                    id,
                    paymentMethodId,
                    price: storedData.price,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                const paymentConfirmation = response.data;
                console.log(paymentConfirmation);
                try {
                    setLoading(true)
                    const data = new FormData();
                    data.append('name', datas.fullName);
                    data.append('email', datas.email);
                    data.append('number', datas.mobileNo);
                    data.append('postalCode', datas.zipCode);
                    data.append('city', datas.city);
                    data.append('state', datas.state);
                    data.append('password', datas.password);
                    data.append('membershipCard', datas.membershipCard);
                    data.append('membership_id', id);
                    data.append('transaction_details', paymentConfirmation.paymentIntent.id);
                    const response = await axios.post(`${API_URL}/auth/signup`, data);
                    toast.success(response.data.message)
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                    setLoading(false)
                    setError(null)
                    setPaymentSucceeded(true)
                    localStorage.removeItem('plandata')
                    onCloseModal()
                    navigate(`/signin`)
                } catch (error) {
                    console.log(error);
                    toast.error("oops try again later!")
                    setLoading(false)
                }
                
            } catch (error) {
                setLoading(false);
                setError(error.message);
            }
        }
    };

    return (
        <div>
            <form id="payment-form" onSubmit={handleSubmit}>
                <CardElement id="card-element" options={CARD_ELEMENT_OPTIONS} />
                <center>
                    <button className="common_btn" type="submit" disabled={!stripe || loading || paymentSucceeded}>
                        {loading ? "Processing..." : `Pay $${storedData.price}` }
                    </button>
                </center>
            </form>
            {error && <div className="card-error" role="alert">{error}</div>}
            {paymentSucceeded && <div className="result-message">Payment succeeded!</div>}
        </div>
    );
};
