import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <>
            <footer className="pt_100 mt_120 xs_mt_100">
                <div className="container">
                    <div className="row justify-content-between wow fadeInUp">
                        <div className="col-lg-6 col-md-4">
                            <div className="footer_info">
                                <Link className="footer_logo" to="index.html">
                                    <img
                                        src="/images/footer_logo.png"
                                        alt="RESTINA"
                                        className="img-fluid w-100"
                                    />
                                </Link>
                                <p>
                                    Welcome to our vibrant cafeteria website, where deliciousness meets convenience.
                                    Explore a tempting array of freshly prepared meals, snacks, and beverages, tailored to satisfy your cravings.
                                </p>
                                <ul>
                                    <li>
                                        <Link className="facebook" to="#">
                                            <i className="fab fa-facebook-f" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="twitter" to="#">
                                            <i className="fab fa-twitter" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="linkedin" to="#">
                                            <i className="fab fa-linkedin-in" />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-md-4">
                            <div className="footer_link">
                                <h3>My Links</h3>
                                <ul>
                                    <li>
                                        <Link to="/">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="#">I've a restaurant</Link>
                                    </li>
                                    <li>
                                        <Link to="/pricing">Pricing</Link>
                                    </li>
                                    <li>
                                        <Link to="#">Deals</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3 col-sm-6 col-md-4">
                            <div className="footer_link">
                                <h3>Other Links</h3>
                                <ul>
                                    <li>
                                        <Link to="#">Contact us</Link>
                                    </li>
                                    <li>
                                        <Link to="#">About</Link>
                                    </li>
                                    <li>
                                        <Link to="/signup">Signup</Link>
                                    </li>
                                    <li>
                                        <Link to="/signin">Signin</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            <div className="progress-wrap">
                <svg
                    className="progress-circle svg-content"
                    width="100%"
                    height="100%"
                    viewBox="-1 -1 102 102"
                >
                    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
                </svg>
            </div>
        </>

    )
}
