import React, { useEffect } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'



export default function About() {
    useEffect(() => {
        document.title = "About us - Grandsave";
    })
    return (
        <>
            <Navbar></Navbar>
            <section className="about_us_story pt_95 xs_pt_65">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-xl-6 wow fadeInLeft">
                            <div className="about_us_story_text">
                                <h2>We invite you to visit our restaurant</h2>
                                <p>
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                                    accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                                    quae ab illo inventore veritatis et quasi architecto beatae vitae
                                    dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
                                    aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
                                    eos qui ratione voluptatem sequi nesciunt
                                </p>
                                <p>
                                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                                    accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                                    quae ab illo inventore veritatis et quasi architecto beatae vitae
                                    dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
                                    aspernatur aut odit aut fugit
                                </p>
                                <a className="common_btn" href="menu_03.html">
                                    <span className="icon">
                                        <img
                                            src="/images/eye.png"
                                            alt="order"
                                            className="img-fluid w-100"
                                        />
                                    </span>
                                    View All Menu
                                </a>
                            </div>
                        </div>
                        <div className="col-xl-5">
                            <div className="about_us_story_img">
                                <div className="row">
                                    <div className="col-lg-8 col-sm-6 wow fadeInLeft">
                                        <div className="about_us_story_img_large">
                                            <img
                                                src="/images/about_story_img_1.jpg"
                                                alt="story"
                                                className="img-fluid w-100"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 wow fadeInRight">
                                        <div className="about_us_story_img_small">
                                            <img
                                                src="/images/about_story_img_3.jpg"
                                                alt="story"
                                                className="img-fluid w-100"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-sm-6 wow fadeInLeft">
                                        <div className="about_us_story_img_small">
                                            <img
                                                src="/images/about_story_img_4.jpg"
                                                alt="story"
                                                className="img-fluid w-100"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-sm-6 wow fadeInRight">
                                        <div className="about_us_story_img_large">
                                            <img
                                                src="/images/about_story_img_2.jpg"
                                                alt="story"
                                                className="img-fluid w-100"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Footer></Footer>

        </>
    )
}


