import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import Breadcumbs from '../components/Breadcumbs'
import '../assets/css/pricing.css'
import { Link } from 'react-router-dom'
import Footer from '../components/Footer'
import axios from 'axios'
import { API_URL } from '../components/Constant'
import { SkeletonText } from '../components/Skeleton'
import { useNavigate } from 'react-router-dom';



export default function Pricing() {
    useEffect(() => {
        document.title = "Membership - Grandsave";
    },[])

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();

    console.log(data);
    const datafetch = async () => {
        const response = await axios.get(`${API_URL}/general/memberships-plan`);
        setData(response.data)
        setLoading(false)
    }
    useEffect(() => {
        datafetch();
    }, [])

    const handleSubscribe = (id, title, price) => {
        const data = { id, title, price };
        localStorage.setItem('plandata', JSON.stringify(data));        
        navigate(`/signup`);
    };
    return (
        <>
            <Navbar></Navbar>
            <br></br><br></br>

            {loading ?
                <>
                    <div class="container contact_form">
                        <br></br>
                        <h2 class="text-center mb-5 mt-5">Membership Plans</h2>
                        <div className="skeleton-wrapper">
                            <SkeletonText width="100%" height="300px" />
                        </div>
                    </div>
                </>
                :
                <>
                    <div>
                        <div class="container contact_form">
                            <br></br>
                            <h2 class="text-center mb-5 mt-5">Membership Plans</h2>
                            <div class="plans">
                                {data.map((item, index) => (
                                    <div class="plan popular">
                                        <h3 class="plan-title mt-4">{item.title}</h3>
                                        <p class="price">{item.description}</p>
                                        <p class="price"></p>
                                        <p class="cost mb-3">${item.price} / {item.type}</p>
                                        <ul class="benefits">
                                            {item.services.map((dt, index)=>(
                                                <p><i className="pi pi-arrow-right"></i> {dt}</p>
                                            ))}
                                        </ul>
                                        <button className="common_btn mt-3" onClick={()=>handleSubscribe(item._id, item.title, item.price)}>Subscribe</button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </>

            }



            <Footer></Footer>
        </>
    )
}
