import React, { useEffect, useState } from 'react'
import Links from "../components/Links";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import axios from 'axios';
import { API_URL, IMG_URL } from '../components/Constant';
import { SkeletonText } from '../components/Skeleton';
import { Link } from 'react-router-dom';



export default function Index() {

    useEffect(() => {
        document.title = "Home - Grandsave";
    },[])

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const datafetch = async () => {
        setLoading(true)
        const response = await axios.post(`${API_URL}/restaurants/fetchlatest/`, data);
        setData(response.data)
        setLoading(false)
    }

    useEffect(() => {
        datafetch();
    }, [])


    return (
        <>
            <Navbar></Navbar>
            <section className="banner" style={{ background: "url(/images/banner_bg.jpg)" }}>
                <div className="container container_large">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-xxl-7 col-lg-7 col-xl-7 col-md-9">
                            <div className="banner_text wow fadeInUp">
                                <h5>Save Big, Eat Well.</h5>
                                <h1 style={{ fontSize: '46px', textTransform: 'none' }} >Exclusive Dining Deals and Membership Club</h1>
                                <br></br>
                                <p style={{ textAlign: 'left', fontSize: '18px' }}>
                                    Explore a world of culinary delights with our restaurant membership plans. Enjoy exclusive discounts, priority seating, and special menu access at top eateries, enhancing every dining experience. Join us to savor more for less!
                                </p>
                            </div>
                        </div>
                        <div className="col-xxl-4 col-lg-5 col-xl-5">
                            <div className="banner_img wow fadeInRight">
                                <div className="img">
                                    <img src="/images/banner_img.png" alt="banner" className="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="menu_item pt_125 xs_pt_85">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 m-auto wow fadeInUp">
                            <div className="section_heading mb_45 xs_mb_50">
                                <h2>Amazings Offers</h2>
                            </div>
                        </div>
                    </div>
                    <div id="schedule">
                        <div className="colorful-tab-wrapper" id="filter_area">
                            <div className="row">
                                <div className="col-12">
                                    <div className="colorful-tab-content active" id="item_1">
                                        {loading ?
                                            <>
                                                <div className="row">
                                                    <div className="col-xl-4 col-lg-4 col-sm-6 wow fadeInUp">
                                                        <div className="skeleton-wrapper">
                                                            <SkeletonText width="100%" height="300px" />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-lg-4 col-sm-6 wow fadeInUp">
                                                        <div className="skeleton-wrapper">
                                                            <SkeletonText width="100%" height="300px" />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-lg-4 col-sm-6 wow fadeInUp">
                                                        <div className="skeleton-wrapper">
                                                            <SkeletonText width="100%" height="300px" />
                                                        </div>
                                                    </div>

                                                </div>

                                            </>
                                            :
                                            <>
                                                <div className="row">
                                                    {data.length > 0 ?
                                                        <>
                                                            {data.map((item, index) => (
                                                                <div className="col-xl-4 col-lg-4 col-sm-6 wow fadeInUp">
                                                                    <div className="single_menu">
                                                                        <div className="single_menu_img">
                                                                            <Link className="title" to={`/restaurants/${item._id}`}>
                                                                                <img src={`${item.profilePicture}`} className="img-fluid w-100" />
                                                                            </Link>
                                                                        </div>
                                                                        <div className="single_menu_text">
                                                                            <Link className="category" to={`/restaurants/${item._id}`}>
                                                                                {item.businessOffer[0].value}
                                                                            </Link>
                                                                            <Link className="title" to={`/restaurants/${item._id}`}>
                                                                                {item.name} <span style={{ fontSize: '15px' }} >({item.city})</span>
                                                                            </Link>
                                                                            <Link className="title" to={`/restaurants/${item._id}`}>
                                                                                <p className="descrption">
                                                                                    {item.description}
                                                                                </p>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </>
                                                        :
                                                        <>
                                                            <center>
                                                                <div style={{ marginTop: '10%' }} >
                                                                    <img src="/question-mark.png" style={{ width: '100px' }} ></img>
                                                                    No Restaurants exits in our records according to applied filters!
                                                                </div>
                                                            </center>
                                                        </>
                                                    }
                                                </div>
                                            </>

                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </>
    )
}
