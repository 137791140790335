import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { Navigate } from "react-router-dom";

const Logout = () => {
  useEffect(() => {
    localStorage.removeItem('GrandsaveAhadUser');
    toast.success("Logout successfully!")
  }, []);

  return <Navigate to="/signin" />;
};

export default Logout;
