import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from './user/pages/Index';
import 'primeicons/primeicons.css';
import Pricing from './user/pages/Pricing';
import Signup from './user/pages/Signup';
import { PrimeReactProvider } from 'primereact/api';
import 'primereact/resources/primereact.css';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import Contact from './user/pages/Contact';
import Signin from './user/pages/Signin';
import About from './user/pages/About';
import Ihaveres from './user/pages/Ihaveres';
import Deals from './user/pages/Deals';
import toast, { Toaster } from 'react-hot-toast';
import { CookiesProvider } from 'react-cookie';
import Authmiddleware from './user/components/Authmiddleware';
import RestaurantsDetail from './user/pages/RestaurantsDetail';
import Dashboard from './user/pages/Dashboard';
import Logout from './user/pages/Logout';

function App() {
  return (
    <>
      <PrimeReactProvider>
        <CookiesProvider defaultSetOptions={{ path: '/' }}>
          <BrowserRouter>
            <Routes>
              <Route >
                <Route index element={<Index />} />
                <Route path="buy-membership" element={<Pricing />} />
                <Route path="signup" element={<Signup />} />
                <Route path="signin" element={<Signin />} />
                <Route path="contact-us" element={<Contact />} />
                <Route path="search-restaurants" element={<Deals />} />
                <Route path="restaurants/:id" element={<RestaurantsDetail />} />
                <Route path="about-us" element={<About />} />
                <Route path="join-us" element={<Ihaveres />} />
                <Route path="dashboard" element={<Authmiddleware><Dashboard /></Authmiddleware>} />
                <Route path="logout" element={<Authmiddleware><Logout /></Authmiddleware>} />


                {/* <Route path="*" element={<NoPage />} /> */}
              </Route>
            </Routes>
          </BrowserRouter>
        </CookiesProvider>
      </PrimeReactProvider>
      <Toaster />
    </>
  );
}

export default App;
