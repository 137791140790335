import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import axios from 'axios';
import { API_URL, hasTimePassed, isoToHumanReadable } from '../components/Constant';
import toast from 'react-hot-toast';



export default function Dashboard() {
    const user_data = JSON.parse(localStorage.getItem('GrandsaveAhadUser'))
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const datafetch = async () => {
        setLoading(true)
        const data = new FormData();
        data.append('id', user_data._id);
        const response = await axios.post(`${API_URL}/auth/membership-history`, data);
        setData(response.data)
        setLoading(false)
    }

    useEffect(() => {
        datafetch();
    }, [])

    useEffect(() => {
        document.title = "Dashboard - Grandsave";
    },[])

    return (
        <>
            <Navbar />
            <br></br><br></br><br></br><br></br><br></br>
            <section className="cart_view   xs_mt_95">
                <div className="container">
                    <h1 class="text-center mb-5">Membership History</h1>
                    <div className="row wow fadeInUp">
                        <div className="col-lg-12">
                            <div className="cart_list">
                                <div className="table-responsive">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="pro_img">Name</th>
                                                <th className="pro_img">Price</th>
                                                <th className="pro_img">Start Date</th>
                                                <th className="pro_img">Expire Date</th>
                                                <th className="pro_img">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((item, index) => (
                                                <tr>
                                                    <td className="pro_tk">
                                                        <h6>{item.title}</h6>
                                                    </td>
                                                    <td className="pro_tk">
                                                        <h6>${item.price}</h6>
                                                    </td>
                                                    <td className="pro_tk">
                                                        <h6>{ isoToHumanReadable(item.start_date)}</h6>
                                                    </td>
                                                    <td className="pro_tk">
                                                        <h6>{ isoToHumanReadable(item.exp_date)}</h6>
                                                    </td>
                                                    <td className="pro_tk">
                                                    <td>{!hasTimePassed(item.exp_date) ? <><button className='badge bg-success' >Active</button></> : <><button className='badge bg-danger' >Expired</button></>}</td>
                                                    </td>
                                                </tr>
                                            ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
}
